import revive_payload_client_RUcuuZdRrG from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_72N2rJ8S9f from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DfFtGa6TMR from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_0XRbaJXHNn from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_46uhNzWELv from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XXDBA7a9bU from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_etYqlEF9QQ from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@3.29.4_typescript@5.5.4_vue@3.4.38_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/.nuxt/components.plugin.mjs";
import prefetch_client_k3THQGMPCT from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_eslint@8.57.0_ioredis@5.4.1_magicast@0_a4kabqj7nm2m3whzilcjt2b5yy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/.nuxt/unocss.mjs";
import sentry_client_fymoo67wuL from "/builds/asar-studio/ticket-me-biz/ticketme/libs/common/dist/runtime/plugins/sentry.client.js";
import plugin_Xcw5zktDcU from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typesc_63kp3co346ep75k3fawchpi66i/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_RUcuuZdRrG,
  unhead_72N2rJ8S9f,
  router_DfFtGa6TMR,
  navigation_repaint_client_0XRbaJXHNn,
  check_outdated_build_client_46uhNzWELv,
  chunk_reload_client_XXDBA7a9bU,
  plugin_vue3_etYqlEF9QQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k3THQGMPCT,
  unocss_MzCDxu9LMj,
  sentry_client_fymoo67wuL,
  plugin_Xcw5zktDcU
]
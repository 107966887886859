import { default as _91id_93oRakEXxkdlMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/activities/[id].vue?macro=true";
import { default as verify0O0lAz8JE6Meta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/auth/vk/verify.vue?macro=true";
import { default as _91name_93D1HMDCgJ0uMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/categories/[name].vue?macro=true";
import { default as events_45by_45dates9PpacIgaMwMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/events-by-dates.vue?macro=true";
import { default as index5RTMnxA1gqMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/filter-events/index.vue?macro=true";
import { default as index5hj8oLKaxLMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/index.vue?macro=true";
import { default as infocjI9VQy744Meta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/info.vue?macro=true";
import { default as login8BXqUq1rXjMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/login.vue?macro=true";
import { default as _91id_93PXaJg6AKPWMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/orders/[id].vue?macro=true";
import { default as _91id_93M1yj2MT0vmMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/organizations/[id].vue?macro=true";
import { default as partnershipzmGmUjD8bXMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/partnership.vue?macro=true";
import { default as privacyW2Sz5cinuHMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/privacy.vue?macro=true";
import { default as questionsWd6JCxBXO4Meta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/questions.vue?macro=true";
import { default as searchzoe5l6blxyMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/search.vue?macro=true";
import { default as ticket_45refundB3zrAo3cWeMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/ticket-refund.vue?macro=true";
import { default as edit_45profilezAvgJDBQCiMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/edit-profile.vue?macro=true";
import { default as favouritesaHDkFbdmubMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/favourites.vue?macro=true";
import { default as indexolfA6xSpUmMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/index.vue?macro=true";
import { default as _91id_93Mns63HxgOMMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/orders/[id].vue?macro=true";
import { default as indexaa2RgMlfrVMeta } from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/orders/index.vue?macro=true";
export default [
  {
    name: "activities-id",
    path: "/activities/:id()",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/activities/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-vk-verify",
    path: "/auth/vk/verify",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/auth/vk/verify.vue").then(m => m.default || m)
  },
  {
    name: "categories-name",
    path: "/categories/:name()",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/categories/[name].vue").then(m => m.default || m)
  },
  {
    name: "events-by-dates",
    path: "/events-by-dates",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/events-by-dates.vue").then(m => m.default || m)
  },
  {
    name: "filter-events",
    path: "/filter-events",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/filter-events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/info.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "organizations-id",
    path: "/organizations/:id()",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/organizations/[id].vue").then(m => m.default || m)
  },
  {
    name: "partnership",
    path: "/partnership",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "questions",
    path: "/questions",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "ticket-refund",
    path: "/ticket-refund",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/ticket-refund.vue").then(m => m.default || m)
  },
  {
    name: "user-edit-profile",
    path: "/user/edit-profile",
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: "user-favourites",
    path: "/user/favourites",
    meta: favouritesaHDkFbdmubMeta || {},
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/favourites.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: indexolfA6xSpUmMeta || {},
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-orders-id",
    path: "/user/orders/:id()",
    meta: _91id_93Mns63HxgOMMeta || {},
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-orders",
    path: "/user/orders",
    meta: indexaa2RgMlfrVMeta || {},
    component: () => import("/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/pages/user/orders/index.vue").then(m => m.default || m)
  }
]